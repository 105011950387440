/*
 *@Author: 段彩翔
 *@Date: 2022-04-12 11:42:24
 *@Description: Before we Start模块头像、表单组件
*/
<template>
	<div class="displayFlex flexAlignCenter mt20px ">
		<!-- 头像 -->
		<div>
			<img :src="avatarSrc" alt="" @click="addPerson" />
			<span class="TextAlginCenter" style="display: inherit;">
				{{startForm.title}}
				<span v-show="startForm.title">
					.<i class="el-icon-delete" @click="delPerson"></i>
				</span>
			</span>
		</div>
		<!-- 具体信息 -->
		<div class="w70 ml100px">
			<!-- 人物选择 -->
			<div v-for="(item, index) in characterType" @click="checkCharacter(item, 'click')" :aria-disabled="!startForm.title" :key="index" :class="startForm.title === item ? 'circle1 circleActive' : 'circle1'">
				{{item}}
			</div>
			<!-- 表单 -->
			<el-form :model="startForm" :rules="rules" ref="form" label-position="left" class="mt40px my-form">
				<el-row>
					<el-col :span="24">
						<el-form-item label="Full Name (as in NRIC)" prop="full_name" :class="!startForm.title ? '' : 'require'">
							<el-input v-model="startForm.full_name" :disabled="!startForm.title" @input="startForm.full_name = $util.inputReg(startForm.full_name)"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="Preferred Name" prop="surname" :class="!startForm.title ? '' : 'require'">
							<el-input v-model="startForm.surname" :disabled="!startForm.title" @input="startForm.surname = $util.inputReg(startForm.surname)"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="Identity Type" prop="identity_type" :class="!startForm.title ? '' : 'require'">
							<el-select v-model="startForm.identity_type" :disabled="!startForm.title" placeholder="">
								<el-option v-for="(item, index) in identityTypeOptions" :key="index" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="NRIC / Passport / FIN" prop="nric_passport_fin" :class="!startForm.title ? '' : 'require'">
							<el-input v-model="startForm.nric_passport_fin" @blur="getMessageByNRIC(startForm.nric_passport_fin)" :disabled="!startForm.title" @input="startForm.nric_passport_fin = $util.inputReg(startForm.nric_passport_fin)"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="Area" prop="contact_no_mobile_area" :class="!startForm.title ? '' : 'require'">
							<el-select v-model="startForm.contact_no_mobile_area" @change="validateMobile" prop="contact_no_mobile_area" :disabled="!startForm.title" no-data-text="No Data" placeholder="">
								<el-option v-for="(item, index) in areaOptions" :key="index" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="Mobile" prop="contact_no_mobile" :class="!startForm.title ? '' : 'require'">
							<el-input v-model="startForm.contact_no_mobile" :disabled="!startForm.title" @input="startForm.contact_no_mobile = $util.inputReg(startForm.contact_no_mobile)"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="Gender" prop="gender" :class="!startForm.title ? '' : 'require'">
							<el-select v-model="startForm.gender" :disabled="!startForm.title || startForm.title !== 'Dr'" placeholder="">
								<el-option label="Male" value="Male"></el-option>
								<el-option label="Female" value="Female"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<!-- 扫描二维码导入信息 -->
		<div class="importBox" @click="routerPush">
			Import from
			<img src="../../../assets/img/myinfo.png" alt="" style="height: 17px;width: 45px;">
			and Save Time
		</div>
		<!-- 弹窗 -->
		<ElementDialog Title="Client" :Visible.sync="Dialog.visible">
			<ElementTable slot="content" :configData="configData" :tableData="tableData" @rowClick="rowClick" height="400">
			</ElementTable>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" @click="Dialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>
import '@/common/style/MyForm.scss'
import { getCopyList, getCopyDetils } from '@/api/KYC'
import ElementDialog from '../../../components/ElementDialog/index.vue'
import ElementTable from '../../../components/ElementTable.vue'
import { GET_areaCodeList, GET_username } from '../../../utils/localStorage'
export default {
	components: { ElementDialog, ElementTable },
	props: {
		// 人物性别
		character: {
			type: String,
			default: ''
		},
		// 表单数据
		formData: {
			type: Array,
			default: []
		},
		// 区分1和0
		distinguish: {
			type: Number,
			default: 0
		}
	},
	data () {
		var validatorNRIC = (rule, value, callback) => {
			if (this.$validateIC(value) || this.startForm.identity_type === 'Passport') {
				callback()
			} else {
				callback(new Error('The form of NRIC/Passport is not correct'))
			}
		}
		var mobileCheck = async (rules, value, callback) => {
			if (this.startForm.contact_no_mobile_area === '+65') {
				if (value) {
					var reg = /^[8,9]\d{7}$/
					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error('The form of Mobile is not correct'))
					}
				} else {
					callback(new Error('Mobile is required'))
				}
			}
		}
		return {
			avatarSrc: '',
			characterType: ['Mr', 'Mrs', 'Mdm', 'Miss', 'Dr'],
			identityTypeOptions: null,
			areaOptions: [],
			distinguish_other: null,
			startFormDisabled: {},
			startForm: {},
			rules: {
				full_name: [{ required: true, message: 'Full Name (as in NRIC) is required', trigger: 'blur' }],
				surname: [{ required: true, message: 'Preferred Name is required', trigger: 'blur' }],
				nric_passport_fin: [
					{ required: true, message: 'NRIC / Passport / FIN is required', trigger: 'blur' },
					{ validator: validatorNRIC, trigger: 'blur' }
				],
				contact_no_mobile: [{ required: true, message: 'Mobile is required', trigger: 'blur' },
				{ validator: mobileCheck, trigger: 'blur' }
				],
			},
			Dialog: {
				visible: false,
				status: "Add",
			},
			tableData: [],
			kycAllData: Object.assign({}, global.kycParam)
		}
	},
	computed: {
		configData () {
			return [
				{
					label: '',
					type: 'index',
					fixed: 'left'
				}, {
					label: "KYC No",
					prop: "kyc_no"
				}, {
					label: "Client Name",
					prop: "client_name"
				}, {
					label: "Creat Time",
					prop: "creat_time",
					formatter: (row) => {
						let temp = row.creat_time.split(' ')[0].split('-')
						return temp[2] + '/' + temp[1] + '/' + temp[0]
					}
				}, {
					label: "Contact No",
					prop: "select_client_tel"
				}
			]
		}
	},
	watch: {
		'startForm.gender' (val) {
			if (val == 'Male')  this.avatarSrc = require('@/assets/img/avatar/avatar.mr.lg.png')
			if (val == 'Female')  this.avatarSrc = require('@/assets/img/avatar/avatar.mrs.lg.png')
		}
	},
	methods: {
		validateForm () {
			this.$refs.form.validate((valid) => {
				this.$emit('update:validateResult', valid)
			})
		},
		validateMobile () {
			this.$refs.form.validateField('contact_no_mobile', valid => {})
		},
		// 根据身份证查询
		getMessageByNRIC (val) {
			getCopyList({ nric_passport_fin: val, agent_id: GET_username() }).then(res => {
				if (res.content.length > 0) {
					this.openDialog(res.content)
				}
			})
		},
		// 根据身份证查询，若有数据则列表展示，选中之后返回1.1-1.8内容
		openDialog (res) {
			this.Dialog.visible = true
			this.tableData = res
		},
		// 返回1.1-1.8内容
		rowClick (val) {
			getCopyDetils({ kyc_no: val.kyc_no, client_id: val.client_id }).then(res => {
				this.Dialog.visible = false
				// kycAllData设置两个Client，点击next时，若只有一个client，则删除掉另一个
				const obj = Object.assign({}, global.kycParam)
				let clientObj = obj.kyc.client[0]
				if (this.kycAllData.kyc.client.length === 1) this.kycAllData.kyc.client.push(clientObj)
				Object.keys(res.content).map(key => {
					this.kycAllData.kyc.client[this.distinguish][key] = res.content[key]
				})
				console.log(this.kycAllData.kyc.client);
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
				Object.keys(this.startForm).map(key => {
					this.startForm[key] = this.kycAllData.kyc.client[this.distinguish].personal_information[key]
				})
				this.checkCharacter(this.startForm.title)
				this.validateForm()
			})
		},
		// 切换人物性别
		checkCharacter (val, temp) {
			// 手动点击切换 需要人物存在
			if (temp == undefined || temp === 'click' && this.startForm.title) {
				this.startForm.title = val
				if (val === '') {
					this.avatarSrc = this.distinguish === 0 ? require('@/assets/img/avatar/circle1.png') : require('@/assets/img/avatar/circle2.png')
					this.startForm.gender = ''
				} else if (val === 'Mr') {
					this.avatarSrc = require('@/assets/img/avatar/avatar.mr.lg.png')
					this.startForm.gender = 'Male'
				} else {
					this.avatarSrc = require('@/assets/img/avatar/avatar.mrs.lg.png')
					this.startForm.gender = 'Female'
				}
				if (val === 'Dr') {
					this.startForm.gender = ''
				}
			}
		},
		// 点击左侧+图片  添加人物
		addPerson () {
			let character = ''
			if (!this.formData[this.distinguish_other].title) {
				character = 'Mr'
			}
			if (this.formData[this.distinguish_other].title) {
				character = this.formData[this.distinguish_other].title === 'Mr' ? 'Miss' : 'Mr'
			}
			if (this.avatarSrc.includes('circle')) {
				this.checkCharacter(character)
			}
		},
		// 删除人物
		delPerson () {
			let ifcanDel = this.formData[this.distinguish_other].title !== ''
			if (ifcanDel) {
				this.$refs.form.resetFields()
				this.checkCharacter('')
			}
		},
		// Myinfo
		routerPush () {
			// 进入myinfo页面之前，需要保存当前页面数据
			this.$emit('setPageData')
			if (this.startForm.title) this.$router.push('/MyInfo?index=' + this.distinguish)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.identityTypeOptions = global.identityType
		this.areaOptions = []
		GET_areaCodeList().map(list => {
			let area = list.areaName + '(' + list.areaCode + ')'
			this.areaOptions.push({ code: list.areaCode, name: area })
		})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.startForm = this.formData[this.distinguish]
		this.distinguish_other = this.distinguish === 0 ? 1 : 0
		this.avatarSrc = this.distinguish === 0 ? require('@/assets/img/avatar/circle1.png') : require('@/assets/img/avatar/circle2.png')
		if (this.character) this.checkCharacter(this.character)
		// 如果从myinfo页面返回
		if (this.$router.currentRoute.query.personData !== undefined) {
			Object.keys(this.startForm).map(key => {
				this.startForm[key] = this.$store.state.KYC.kycAllData.kyc.client[this.distinguish].personal_information[key]
			})
			if (this.startForm.title) this.checkCharacter(this.startForm.title)
		}
	},
	destroyed () {
	}
}
</script>
<style lang='scss' scoped>
img {
	width: 180px;
	height: 180px;
	margin-top: -200px;
}

.circle1 {
	width: 50px;
	height: 50px;
	border: 1px solid #707070;
	border-radius: 50%;
	display: inline-block;
	margin: 0 20px;
	line-height: 50px;
	text-align: center;
	font-size: 14px;
	color: #67645d;
	cursor: pointer;
}
.circleActive {
	border: 1px solid #f87f18;
}
.importBox {
	border: 1px solid #c1c1c1;
	border-radius: 6px;
	color: #707070;
	padding: 6px;
	font-size: 12px;
	cursor: pointer;
}
</style>