<template>
	<!-- :header-cell-style="{ background: 'rgb(245, 247, 250)' }" -->
	<el-table :border="border" :height="height" :resizable="Resizable" :data="tableData" :row-key="keyVal" style="width: 100%" @selection-change="Selection" @row-click="rowClick">
		<span slot="empty">No data</span>
		<div v-for="(item, index) in configData" :key="index">
			<!-- 插槽 -->
			<slot v-if="item.slot" :name="item.slot" fixed="right"></slot>
			<!-- 带多选 -->
			<el-table-column v-else-if="item.type == 'select'" :fixed="item.fixed" :type="item.type" :width="item.width" :min-width="item.minWidth" :align="item.align || 'center'">
			</el-table-column>
			<!-- 带序号 -->
			<el-table-column v-else-if="item.type == 'index'" :type="item.type" :fixed="item.fixed" :width="item.width || '60px'" :label="item.label" :min-width="item.minWidth" :align="item.align || 'center'">
			</el-table-column>
			<!-- 图片 -->
			<el-table-column v-else-if="item.type == 'image'" :type="item.type" :prop="item.prop" :label="item.label" width="140" min-width="120" :align="item.align || 'center'">
				<template slot-scope="scope">
					<el-image style="width: 140px; height: 60px" :src="scope.row.logo" :preview-src-list="[...scope.row.logo]">
					</el-image>
				</template>
			</el-table-column>
			<!-- 状态 -->
			<el-table-column :resizable="false" v-else-if="item.formatter" :type="item.type" show-overflow-tooltip :label="item.label" :width="item.width" :formatter="item.formatter" :min-width="item.minWidth" :align="item.align || 'center'">
			</el-table-column>
			<!-- 金额 -->
			<el-table-column v-else-if="item.type == 'amount'" :type="item.type" :prop="item.prop" :label="item.label" :width="item.width" min-width="100px" :align="item.align||'center'">
				<template slot-scope="scope">
					S$ {{$formatMoney(scope.row[item.prop])}}
				</template>
			</el-table-column>
			<!-- 普通 -->
			<el-table-column v-else :type="item.type" show-overflow-tooltip :prop="item.prop" :label="item.label" :width="item.width" :min-width="item.minWidth" :align="item.align||'center'">
			</el-table-column>
		</div>
	</el-table>
</template>

<script>
export default {
	props: {
		// 规则
		configData: {
			type: Array,
		},
		// 数据
		tableData: {
			type: Array,
		},
		// 边框
		border: {
			type: Boolean,
			default: false
		},
		// 高度
		height: {
			type: String,
			default: '180'
		},
		// 是否拖动宽度
		Resizable: {
			type: Boolean,
			default: false
		},
		// key
		keyVal: {
			type: String,
			default: 'id'
		},
	},
	data () {
		return {};
	},
	computed: {},
	methods: {
		// 返回选中状态
		Selection (val) {
			this.$emit("Selection", val);
		},
		// 行点击事件
		rowClick (val) {
			this.$emit("rowClick", val)
		}
	}
};
</script>
<style lang="css" scoped>
</style>
