/*
 *@Author: 段彩翔
 *@Date: 2022-04-11 16:41:49
 *@Description: 创建人物
*/
<template>
	<div class='displayFlex flexColumn h100 boxSizing' style="padding: 20px 60px;">
		<div class="h60px">
			<h1>Before We Start</h1>
			<span @click="$router.push('/')" class="Cancel">Cancel</span>
		</div>
		<div class="displayFlex-1 wrapper">
			<!-- 上 -->
			<MyForm ref="form0" :validateResult.sync="disabledBtn0" :formData="startForm" :distinguish="0" character="Mr" @setPageData="setPageData"/>
			<!-- 下 -->
			<MyForm ref="form1" :validateResult.sync="disabledBtn1" :formData="startForm" :distinguish="1" character="" @setPageData="setPageData"/>
		</div>
		<!-- 确认按钮 -->
		<div class="h60px TextAlginRight">
			<el-button @click="Next" type="warning" size="medium">Next</el-button>
		</div>
	</div>
</template>

<script>
import MyForm from './components/MyForm.vue'
import { kycUpdate } from '@/api/KYC'
import { GET_username } from '../../utils/localStorage';

export default {
	components: { MyForm },
	data () {
		return {
			disabledBtn0: false,
			disabledBtn1: false,
			startForm: [{
				full_name: '',
				surname: '',
				nric_passport_fin: '',
				contact_no_mobile_area: '',
				contact_no_mobile: '',
				gender: '',
				identity_type: '',
				title: ''
			}, {
				full_name: '',
				surname: '',
				nric_passport_fin: '',
				contact_no_mobile_area: '',
				contact_no_mobile: '',
				gender: '',
				identity_type: '',
				title: ''
			}]
		};
	},
	computed: {},
	watch: {},
	methods: {
		Next () {
			// 如果创建任务为0或者表单校验不通过，则不允许提交
			let person = 0
			if (this.startForm[0].title) {
				this.$refs.form0.validateForm()
				person += 1
			}
			if (this.startForm[1].title) {
				this.$refs.form1.validateForm()
				person += 1
			}
			// 为了保证校验执行完成，添加延迟
			setTimeout(() => {
				console.log(person === 0 || this.startForm[0].title && !this.disabledBtn0 || this.startForm[1].title && !this.disabledBtn1, '表单是否校验通过');
				// 校验form表单是否正确
				if (person === 0 || this.startForm[0].title && !this.disabledBtn0 || this.startForm[1].title && !this.disabledBtn1) return false
				// kyc所有的参数
				let kycParam = this.$store.state.KYC.kycAllData
				// 判断vuex中kycalldata是否是空对象，若为空，则将表单数据填入并提交，若不为空，则判断创建人物数量，删除不需要的数据并提交
				if (JSON.stringify(kycParam) === '{}') {
					kycParam = JSON.parse(JSON.stringify(global.kycParam))
				}
				// 判断有几个人物，若有两个则不操作，若有一个，需将另外一个删除
				if (person === 1 && kycParam.kyc.client.length === 2) {
					if (this.startForm[0].title) {
						// 删除client1
						kycParam.kyc.client.splice(1, 1)
					} else if (this.startForm[1].title) {
						// 删除client0
						kycParam.kyc.client.splice(0, 1)
					}
				}
				kycParam.kyc.agent_id = GET_username()
				if (this.startForm[0].title) {
					Object.keys(this.startForm[0]).map(key => {
						kycParam.kyc.client[0].personal_information[key] = this.startForm[0][key]
					})
				}
				if (this.startForm[1].title) {
					Object.keys(this.startForm[1]).map(key => {
						// 如果第一个人物不存在，只创建了人物2，则需要将值赋给client0
						if (!this.startForm[0].title) {
							kycParam.kyc.client[0].personal_information[key] = this.startForm[1][key]
						} else {
							kycParam.kyc.client[1].personal_information[key] = this.startForm[1][key]
						}
					})
				}
				if (!kycParam.kyc.kyc_no) {
					this.updateKYC(kycParam)
				} else {
					// 入参kyc_no不应该有值，若有值，需要排查问题
					console.log('Please contact system administrator!');
				}
			}, 100);
		},

		async updateKYC (kycParam) {
			let res = await kycUpdate(kycParam)
			kycParam.kyc.kyc_no = res.content.kyc_no
			kycParam.kyc.client[0].client_id = res.content.client_id_a
			if (kycParam.kyc.client.length > 1) kycParam.kyc.client[1].client_id = res.content.client_id_b
			// kycs数据
			this.$store.commit('SET_KYC_ALL_DATA', kycParam)
			// 当前节点
			this.$store.commit('SET_KYCPAGEITEM', 0)
			this.$router.push('/KYC&FNA')
		},
		// 将表单值赋给store
		setFormToKYC (index) {
			Object.keys(this.startForm[index]).map(key => {
				kycParam.kyc.client[index].personal_information[key] = this.startForm[index][key]
			})
		},
		// 进入myinfo页面之前，需要保存当前页面数据
		setPageData () {
			let kycParam = this.$store.state.KYC.kycAllData
			if (JSON.stringify(kycParam) === '{}') {
				kycParam = Object.assign({}, global.kycParam)
			}
			if (this.startForm[0].title) this.setFormToKYC(0)
			if (this.startForm[1].title) this.setFormToKYC(1)
			this.$store.commit('SET_KYC_ALL_DATA', kycParam)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		// 创建人物-初始化清空kycalldata(如果从myinfo页面点next返回，则不清空)
		if (this.$router.currentRoute.query.personData === undefined) this.$store.commit('SET_KYC_ALL_DATA', {})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
}
</script>
<style lang='css' scoped>
.wrapper {
	flex-grow: 1;
	flex-basis: 100%;
	overflow-y: scroll;
}
h1 {
	color: #f36a10;
	font-size: 26px;
	font-weight: bold;
	display: inline-block;
}
.Cancel {
	font-size: 22px;
	color: #67645d;
	float: right;
	cursor: pointer;
}
</style>